import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import vars from './vars.module.scss';

const recOneTheme = {
  palette: {
    primary: {
      main: vars.primary
    },
    secondary: {
      main: vars.secondary
    },
    success: {
      main: vars.success
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          background: vars.primaryBackground
        },
        a: {
          color: vars.primary
        },
        '.AuthContainer': {
          minHeight: '90vh',
          alignContent: 'center',
          justifyContent: 'center',
          margin: '25px 0'
        },
        '.ButtonsContainer': {
          marginTop: '50px'
        },
        '.MuiFormControl-root.MuiTextField-root': {
          marginBottom: '14px'
        }
      }
    },
    /*   MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px', // Global font size for input text
          padding: '4px 14px', // Padding inside the input box
        },
      },
    }, */
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          '& .MuiSelect-select': {
            padding: '8px 16px' // Adjust padding within the select box
          },
          '& .MuiOutlinedInput-input': {
            padding: '8px 14px' // Adjust padding within the input box
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px', // Font size for each item in the dropdown
          padding: '8px 16px'
          /*   '&:hover': {
            backgroundColor: 'red', // Hover effect for dropdown items
          }, */
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: vars.primaryLabelColor, // Label color
          transform: 'translate(14px, 8px) scale(1)' // Adjust label position
        },
        shrink: {
          transform: 'translate(14px, -8px) scale(0.75)' // Adjust position when focused/shrunk
        },
        formControl: {
          fontSize: '13px',
          opacity: vars.primaryOpacity // Adjust font size when used with form control
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            fontSize: '14px', // Global font size for the input box
            padding: '4px 14px !important' // Padding inside the input box
          }
        },
        option: {
          padding: '10px 14px', // Padding for each item in the dropdown
          fontSize: '14px' // Font size for each item in the dropdown
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '13px', // Font size of chip label
          padding: 0 // Padding inside the chip
        }
      }
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          fontSize: '13px', // Adjust font size for years
          padding: '0', // Adjust padding to control button size
          '& .MuiPickersYear-yearButton': {
            padding: 0, // Adjust padding for year buttons
            fontSize: '13px', // Adjust font size for year buttons
            height: 30,
            width: 50,
            margin: '4px 0',
            '& .Mui-selected': {
              backgroundColor: vars.primary // Adjust background color for selected year
            }
          }
        }
      }
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    title: {
      fontSize: 22,
      fontWeight: 400,
      lineHeight: 28
    },
    subtitle: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 24,
      letterSpacing: 0.1
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 20,
      letterSpacing: 0.1
    },
    labelText: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 16,
      letterSpacing: 0.5
    }
  }
};

type CustomTheme = {
  [Key in keyof typeof recOneTheme]: typeof recOneTheme[Key];
};

declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme {}

  interface ThemeOptions extends CustomTheme {}
}

let theme = createTheme(recOneTheme);
theme = responsiveFontSizes(theme);

export default createTheme(recOneTheme);
