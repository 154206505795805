import { createContext, FC, useContext, useMemo, useState } from 'react';
import { IClient } from '@recone/models';
import { useGetCurrentUser } from '../hooks/api/auth';

export enum AuthStates {
  NotLogged,
  Logged,
  TokenExpired
}

interface AuthContextType {
  token?: any;
  getToken: () => string | null;
  setToken: (token: string) => void;
  removeToken: (sessionExpired?: boolean) => void;
  state: AuthStates;
  refreshToken: string | null;
  saveRefreshToken: (refreshToken: string) => void;
  removeRefreshToken: () => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: FC = ({ children }) => {
  // TODO: Improve after MVP: https://www.rdegges.com/2018/please-stop-using-local-storage/
  const [token, setTokenState] = useState<string | null>(localStorage.getItem('token'));
  const [authState, setAuthState] = useState<AuthStates>(token ? AuthStates.Logged : AuthStates.NotLogged);
  const [refreshToken, setRefreshToken] = useState<string | null>(localStorage.getItem('refreshToken'));

  const setToken = (token: string) => {
    setAuthState(AuthStates.Logged);
    setTokenState(token);
    localStorage.setItem('token', token);
  };

  const removeToken = (sessionExpired = false) => {
    setAuthState(sessionExpired ? AuthStates.TokenExpired : AuthStates.NotLogged);
    localStorage.removeItem('token');

    if (token) {
      setTokenState(null);
    }

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
  };

  const getToken = () => localStorage.getItem('token');

  const saveRefreshToken = (refreshToken: string) => {
    localStorage.setItem('refreshToken', refreshToken);
    setRefreshToken(refreshToken);
  };

  const removeRefreshToken = () => {
    localStorage.removeItem('refreshToken');
    setRefreshToken(null);
  };
  
  return (
    <AuthContext.Provider
      value={{
        token,
        getToken,
        setToken,
        removeToken,
        state: authState,
        refreshToken,
        saveRefreshToken,
        removeRefreshToken
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw 'No Auth context set yet!';
  }

  return authContext;
};
