import { styled } from '@mui/material/styles';
import { AuthCardProps } from './AuthCard.types';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)<AuthCardProps>(({ theme }) => {
  return {
    '&.CardContainer': {
      width: 400,
      padding: 20,
      boxSizing: 'content-box',
      flexShrink: 0
    },
    '.Image img': {
      maxWidth: 265,
      marginBottom: 0
    },
    '.AuthForm': {
      textAlign: 'center'
    }
  };
});
