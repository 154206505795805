import { FC, useEffect, useState } from 'react';
import './EmailVerification.scss';
import Grid from '@mui/material/Grid';
import AuthCard from '../../components/AuthCard';
import Typography from '@mui/material/Typography';
import ReactCodeInput from 'react-verification-code-input';
import Button from '../../components/Button';
import { maskEmail } from '../../helpers/common';
import Link from '@mui/material/Link';

interface EmailVerificationProps {
  userEmail: string;
  userPassword: string;
  onSendCode?: (code: string) => void;
  onResendCode?: (email: string, password: string) => void;
}

const EmailVerification: FC<EmailVerificationProps> = (props) => {
  const { onResendCode, onSendCode, userEmail, userPassword } = props;

  const [value, setValue] = useState('');
  const [userEmailMasked, setUserEmailMasked] = useState(maskEmail(userEmail ?? ''));

  useEffect(() => setUserEmailMasked(maskEmail(userEmail ?? '')), [userEmail]);

  return (
    <Grid className="AuthContainer EmailVerification" container data-testid="EmailVerification" flexGrow={1} xs={12}>
      <AuthCard
        header='Email Verification'
        subHeader={`Please enter 6 - digit passcode. We will send it on ${userEmailMasked}.`}
      >
        <div className='AuthForm'>
          <ReactCodeInput fieldWidth={62} fieldHeight={40} type="text" onChange={setValue} />
          <Button
            className='AuthFormBtn'
            color="primary"
            sx={{ marginTop: '1rem' }}
            fullWidth
            onClick={() => onSendCode?.(value)}
          >
            Send
          </Button>
          <Typography variant='subtitle1'>
            Haven't received your passcode? {' '}
            <Link href="#" onClick={() => onResendCode?.(userEmail, userPassword)}>
              Send it again
            </Link>
          </Typography>
        </div>
      </AuthCard>
    </Grid>
  );
};

export default EmailVerification;
