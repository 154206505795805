/* eslint-disable */
/// @ts-nocheck
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables, headers?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** CompanyType */
export type CompanyType = {
  __typename?: 'CompanyType';
  _id?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['Int']>;
  contactInfo?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  founded?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfJobs?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<Scalars['String']>>>;
  tagline?: Maybe<Scalars['String']>;
  team?: Maybe<TeamType>;
  teamId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** CrawledUserType */
export type CrawledUserType = {
  __typename?: 'CrawledUserType';
  _id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  birthDateOn?: Maybe<LinkedInDateType>;
  createdAt?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<EducationType>>>;
  email?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailInfoType>>>;
  experience?: Maybe<Array<Maybe<ExperienceType>>>;
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  liteUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<ProfilePictureType>;
  salesUrl?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  team?: Maybe<TeamType>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** EducationType */
export type EducationType = {
  __typename?: 'EducationType';
  degreeName?: Maybe<Scalars['String']>;
  end?: Maybe<LinkedInDateType>;
  fieldOfStudy?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  start?: Maybe<LinkedInDateType>;
};

/** EmailInfoType */
export type EmailInfoType = {
  __typename?: 'EmailInfoType';
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** ExperienceType */
export type ExperienceType = {
  __typename?: 'ExperienceType';
  company?: Maybe<CompanyType>;
  companyLinkedinId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  end?: Maybe<LinkedInDateType>;
  start?: Maybe<LinkedInDateType>;
  title?: Maybe<Scalars['String']>;
};

/** LinkedInDateType */
export type LinkedInDateType = {
  __typename?: 'LinkedInDateType';
  day?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type LinkedinUsersSearchInputType = {
  companyName?: InputMaybe<SearchFilterInputType>;
  currentCompanyName?: InputMaybe<SearchFilterInputType>;
  firstName?: InputMaybe<SearchFilterInputType>;
  industry?: InputMaybe<SearchFilterInputType>;
  lastName?: InputMaybe<SearchFilterInputType>;
  location?: InputMaybe<SearchFilterInputType>;
  pastCompanyName?: InputMaybe<SearchFilterInputType>;
  schoolName?: InputMaybe<SearchFilterInputType>;
  skill?: InputMaybe<Array<InputMaybe<SearchFilterInputType>>>;
  yearsInCompany?: InputMaybe<SearchFilterInputType>;
  yearsOfExperience?: InputMaybe<SearchFilterInputType>;
};

/** ProfilePictureType */
export type ProfilePictureType = {
  __typename?: 'ProfilePictureType';
  artifacts?: Maybe<Array<Maybe<Scalars['String']>>>;
  rootUrl?: Maybe<Scalars['String']>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  filter?: Maybe<Array<Maybe<CrawledUserType>>>;
};


export type RootQueryTypeFilterArgs = {
  filter?: InputMaybe<LinkedinUsersSearchInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** SearchFilter input type */
export type SearchFilterInputType = {
  inclusionOption: Scalars['Boolean'];
  queryProperty?: InputMaybe<Scalars['String']>;
};

/** TeamType */
export type TeamType = {
  __typename?: 'TeamType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetFilterProjectQueryVariables = Exact<{
  filter?: InputMaybe<LinkedinUsersSearchInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetFilterProjectQuery = { __typename?: 'RootQueryType', filter?: Array<{ __typename?: 'CrawledUserType', _id?: string | null, firstName?: string | null, skills?: Array<string | null> | null, experience?: Array<{ __typename?: 'ExperienceType', companyName?: string | null, title?: string | null, companyLinkedinId?: string | null, company?: { __typename?: 'CompanyType', companyName?: string | null, website?: string | null, industry?: string | null } | null } | null> | null } | null> | null };


export const GetFilterProjectDocument = `
    query getFilterProject($filter: LinkedinUsersSearchInputType, $limit: Int, $skip: Int) {
  filter(filter: $filter, limit: $limit, skip: $skip) {
    _id
    firstName
    skills
    experience {
      companyName
      title
      companyLinkedinId
      company {
        companyName
        website
        industry
      }
    }
  }
}
    `;
export const useGetFilterProjectQuery = <
      TData = GetFilterProjectQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFilterProjectQueryVariables,
      options?: UseQueryOptions<GetFilterProjectQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetFilterProjectQuery, TError, TData>(
      variables === undefined ? ['getFilterProject'] : ['getFilterProject', variables],
      fetcher<GetFilterProjectQuery, GetFilterProjectQueryVariables>(client, GetFilterProjectDocument, variables, headers),
      options
    );

useGetFilterProjectQuery.getKey = (variables?: GetFilterProjectQueryVariables) => variables === undefined ? ['getFilterProject'] : ['getFilterProject', variables];
;

useGetFilterProjectQuery.fetcher = (client: GraphQLClient, variables?: GetFilterProjectQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFilterProjectQuery, GetFilterProjectQueryVariables>(client, GetFilterProjectDocument, variables, headers);