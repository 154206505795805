import React, { createContext, useState, useEffect, FC, Dispatch, SetStateAction, PropsWithChildren } from "react";
import { useFlags } from 'flagsmith/react';

interface IMainLayout {
    sidebarOpen?: boolean;
    setSidebarOpen?: Dispatch<SetStateAction<boolean>>;
    projectId?: string;
    setProjectId?: (value: string) => void;
    flags?: any;
}

const MainLayoutContext = createContext<IMainLayout>({
    sidebarOpen: false,
    setSidebarOpen: () => { },
    flags: {}
});

const MainLayoutProvider = ({ children }: PropsWithChildren<{}>) => {
    const [sidebarOpen, setSidebarOpen] = useState(localStorage.getItem('sidebarOpen') === 'true');
    const [projectId, setProjectId] = useState<string>(localStorage.getItem('projectId') || '');

    const flags = useFlags(['sequences']);

    useEffect(() => {
        localStorage.setItem('sidebarOpen', sidebarOpen.toString());
    }, [sidebarOpen]);

    useEffect(() => {
        localStorage.setItem('projectId', projectId);
    }, [projectId]);

    return (
        <MainLayoutContext.Provider
            value={{
                sidebarOpen,
                setSidebarOpen,
                projectId,
                setProjectId: (projectId: string) => setProjectId(projectId),
                flags
            }}>
            {children}
        </MainLayoutContext.Provider>
    );
};

export { MainLayoutContext, MainLayoutProvider };