import {
  industries,
  numberOfPresent,
  timeframes,
  companySizes,
  revenueRanges,
  fundingTypeOptions,
  CategoryDataType,
  cbCategories,
  cbCategoryGroups,
  linkedinCompanyTypeOptions
} from '@recone/models';

export const initialFilters: CategoryDataType[] = [
  {
    name: 'Profile & Keywords',
    conjuction: 'AND',
    filters: [
      {
        name: 'First name',
        path: 'profile.firstName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Last name',
        path: 'profile.lastName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Location',
        path: 'profile.location',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Headline keywords',
        path: 'profile.headline',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Description keywords',
        path: 'profile.summary',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      }
    ]
  },
  {
    name: 'Experience & Tenure',
    conjuction: 'AND',
    filters: [
      {
        name: 'Skills',
        path: 'profile.skills',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Job title (Present)',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'title',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: true,
        criteria: []
      },
      {
        name: 'Job title (Past)',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'title',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: false,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Tenure (Present)',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        isTimeframe: true,
        present: true,
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...timeframes]
      },
      {
        name: 'Total experience',
        path: 'totalExperience',
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...timeframes]
      },
      {
        name: 'Experience keywords (Present)',
        filterType: 'multiSelectCreatable',
        path: 'profile.experience',
        valuePath: 'description',
        startPath: 'start',
        endPath: 'end',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false,
        present: true,
        timed: true
      },
      {
        name: 'Experience keywords (Past)',
        filterType: 'multiSelectCreatable',
        path: 'profile.experience',
        valuePath: 'description',
        startPath: 'start',
        endPath: 'end',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false,
        present: false,
        timed: true
      },
      {
        name: 'Multiples companies',
        path: 'numberOfPresentCompanies',
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...numberOfPresent]
      }
    ]
  },
  {
    name: 'Company & Industry',
    conjuction: 'AND',
    filters: [
      {
        name: 'Company Name (Present)',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'companyName',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: true,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Company Name (Past)',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'companyName',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: false,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'CB Categories (Present)',
        path: 'companies.categories',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategories],
        criteria: []
      },
      {
        name: 'CB Categories (Past)',
        path: 'companies.categories',
        past: true,
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategories],
        criteria: []
      },
      {
        name: 'CB Category groups (Present)',
        path: 'categoryGroups',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategoryGroups],
        criteria: []
      },
      {
        name: 'CB Category groups (Past)',
        path: 'categoryGroups',
        past: true,
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategoryGroups],
        criteria: []
      },
      {
        name: 'Location',
        path: 'companies.location',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Company Type',
        path: 'companies.companyType',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: false,
        overrideConjunction: false,
        // options: [...companyTypes],
        criteria: []
      },
      {
        name: 'Description keywords (Present)',
        filterType: 'multiSelectCreatable',
        path: 'companies',
        valuePath: 'description',
        startPath: 'start',
        endPath: 'end',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false,
        present: true,
        timed: true
      },
      {
        name: 'Description keywords (Past)',
        filterType: 'multiSelectCreatable',
        path: 'companies',
        valuePath: 'description',
        startPath: 'start',
        endPath: 'end',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false,
        present: false,
        timed: true
      },
      {
        name: 'Company size - Employee range (Present)',
        path: 'companySize',
        filterType: 'multiSelectFromOptions',
        options: [...companySizes],
        criteria: []
      },
      {
        name: 'Company size - Employee range (Past)',
        path: 'companySize',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...companySizes],
        criteria: []
      },
      {
        name: 'Company Founded On (Present)',
        path: 'companies.foundedYear',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Company Founded On (Past)',
        path: 'companies.foundedYear',
        past: true,
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Linkedin Industry (Present)',
        path: 'companies.industry',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: false,
        overrideConjunction: false,
        options: [...industries],
        criteria: []
      },
      {
        name: 'Linkedin Industry (Past)',
        path: 'companies.industry',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: false,
        overrideConjunction: false,
        options: [...industries],
        past: true,
        criteria: []
      },
      {
        name: 'Linkedin Specialities (Present)',
        path: 'companies.specialities',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Linkedin Specialities (Past)',
        path: 'companies.specialities',
        filterType: 'multiSelectCreatable',
        past: true,
        criteria: []
      },
      {
        name: 'Is company active',
        path: 'companies.isActive',
        filterType: 'toggleSwitch',
        criteria: []
      }
    ]
  },
  {
    name: 'Education',
    conjuction: 'AND',
    filters: [
      {
        name: 'School Name',
        path: 'profile.education.schoolName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Field of study (Diploma major)',
        path: 'profile.education.fieldOfStudy',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Degree name (Diploma type)',
        path: 'profile.education.degreeName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Start date',
        path: 'profile.education.start',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'End date',
        path: 'profile.education.end',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Education keywords',
        path: 'profile.education.description',
        filterType: 'multiSelectCreatable',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      }
    ]
  },
  {
    name: 'Funding & Financials',
    conjuction: 'AND',
    filters: [
      {
        name: 'Lead Investor (Present)',
        path: 'leadInvestorIdentifiers.name',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Lead Investor (Past)',
        path: 'leadInvestorIdentifiers.name',
        filterType: 'multiSelectCreatable',
        criteria: [],
        past: true
      },
      {
        name: 'Total funding amount',
        path: 'fundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
      /*      {
        name: 'Total funding amount (Past)',
        path: 'fundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Total equity funding amount',
        path: 'equityFundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
      /*      {
        name: 'Total equity funding amount (Past)',
        path: 'equityFundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Last funding amount',
        path: 'lastFundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
      /*       {
        name: 'Last funding amount (Past)',
        path: 'lastFundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Last equity funding amount',
        path: 'lastEquityFundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
      /*       {
        name: 'Last equity funding amount (Past)',
        path: 'lastEquityFundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Last funding date (Present)',
        path: 'lastFundingAt',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Last funding date (Past)',
        path: 'lastFundingAt',
        past: true,
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Last funding type (Present)',
        path: 'lastFundingType',
        filterType: 'multiSelectFromOptions',
        options: [...fundingTypeOptions],
        criteria: []
      },
      {
        name: 'Last funding type (Past)',
        path: 'lastFundingType',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...fundingTypeOptions],
        criteria: []
      },
      {
        name: 'Number of funding rounds',
        path: 'numFundingRounds',
        filterType: 'number',
        criteria: []
      },
      /*     {
        name: 'Number of funding rounds (Past)',
        path: 'numFundingRounds',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Revenue Range (Present)',
        path: 'revenueRange',
        filterType: 'multiSelectFromOptions',
        options: [...revenueRanges],
        criteria: []
      },
      {
        name: 'Revenue Range (Past)',
        path: 'revenueRange',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...revenueRanges],
        criteria: []
      },
      {
        name: 'Founding round - Investment type (Present)',
        path: 'fundingRounds.investmentType',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Founding round - Investment type (Past)',
        path: 'fundingRounds.investmentType',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: [],
        past: true
      },
      {
        name: 'Funding round date - Announced on (Present)',
        path: 'fundingRounds.announced_on', //64df937319e76df736036a31
        filterType: 'date',
        criteria: []
      },
      {
        name: 'Funding round date - Announced on (Past)',
        path: 'fundingRounds.announced_on',
        filterType: 'date',
        criteria: [],
        past: true
      },
      {
        name: 'Funding round amount - Money raised',
        path: 'fundingRounds.money_raised.amount',
        filterType: 'number',
        criteria: []
      },
      /*       {
        name: 'Funding round amount - Money raised (Past)',
        path: 'fundingRounds.money_raised.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Investors (Present)',
        path: 'investorIdentifiers.name',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Investors (Past)',
        path: 'investorIdentifiers',
        filterType: 'multiSelectCreatable',
        criteria: [],
        past: true
      },
      {
        name: 'IPO Status (CB)',
        path: 'companies',
        valuePath: 'ipoStatus',
        startPath: 'start',
        endPath: 'end',
        options: ['Private', 'Public'],
        filterType: 'multiSelectFromOptions',
        criteria: [],
        overrideConjunction: false,
        conjunctionOverridable: false,
        present: true,
        timed: true
      },
      {
        name: 'IPO Status (CB) Past',
        path: 'companies',
        valuePath: 'ipoStatus',
        startPath: 'start',
        endPath: 'end',
        options: ['Private', 'Public'],
        filterType: 'multiSelectFromOptions',
        criteria: [],
        conjunctionOverridable: false,
        overrideConjunction: false,
        present: false,
        timed: true
      },
      {
        name: 'IPO Status (LinkedinIn)',
        path: 'companies',
        valuePath: 'liIpoStatus',
        startPath: 'start',
        endPath: 'end',
        filterType: 'multiSelectFromOptions',
        options: [...linkedinCompanyTypeOptions],
        criteria: [],
        conjunctionOverridable: false,
        overrideConjunction: false,
        present: true,
        timed: true
      },
      {
        name: 'IPO Status (LinkedIn) Past',
        path: 'companies',
        valuePath: 'liIpoStatus',
        startPath: 'start',
        endPath: 'end',
        filterType: 'multiSelectFromOptions',
        options: [...linkedinCompanyTypeOptions],
        criteria: [],
        conjunctionOverridable: false,
        overrideConjunction: false,
        present: false,
        timed: true
      }
    ]
  }
];
