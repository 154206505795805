import { AxiosInstance, AxiosResponse } from 'axios';
import config from '../../config';
import { IClient, ICurrentClientDto } from '@recone/models';

const SIGN_IN_PATH = 'clients/login';
const SIGN_UP_PATH = 'clients/register';
const VERIFY_EMAIL_CODE_PATH = 'clients/verify';
const CURRENT_USER_PATH = 'clients/current';
const UPDATE_USER_PATH = 'clients/updateProfile';
const UPDATE_PASSWORD = 'clients/updatePassword';
const FORGOT_PASSWORD = 'clients/forgotPassword';
const RESET_PASSWORD = 'clients/resetPassword ';
const REFRESH_TOKEN = 'clients/refresh-token';

export type UserSignUpType = Pick<IClient, 'name' | 'email' | 'password' | 'surname'> & {
  repeatPassword: string;
  invitationToken?: string;
};

export const signIn = (axiosInstance: AxiosInstance, email: string, password: string) =>
  axiosInstance.post(`${config.service.BASE_URL}/${SIGN_IN_PATH}`, {
    email,
    password
  });

export const signUp = (axiosInstance: AxiosInstance, userInfo: UserSignUpType) =>
  axiosInstance.post(`${config.service.BASE_URL}/${SIGN_UP_PATH}`, userInfo);

export const verify = (axiosInstance: AxiosInstance, email: string, verificationCode: string) =>
  axiosInstance.post(`${config.service.BASE_URL}/${VERIFY_EMAIL_CODE_PATH}`, {
    email,
    verificationCode
  });

export const getCurrentUser = (axiosInstance: AxiosInstance): Promise<AxiosResponse<ICurrentClientDto>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${CURRENT_USER_PATH}`);

export const updateUser = (
  axiosInstance: AxiosInstance,
  id: string,
  name: string,
  surname: string,
  email: string,
  teamId: string
) =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${UPDATE_USER_PATH}`,
    {
      id,
      name,
      surname,
      email
    },
    {
      headers: {
        teamid: teamId
      }
    }
  );

export const updatePassword = (
  axiosInstance: AxiosInstance,
  clientId: string,
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
  teamId: string
) =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${UPDATE_PASSWORD}`,
    {
      clientId,
      oldPassword,
      newPassword,
      confirmPassword
    },
    {
      headers: {
        teamid: teamId
      }
    }
  );

export const forgotPassword = (axiosInstance: AxiosInstance, email: string) =>
  axiosInstance.post(`${config.service.BASE_URL}/${FORGOT_PASSWORD}`, {
    email
  });

export const resetPassword = (axiosInstance: AxiosInstance, code: string, password: string, repeatPassword: string) =>
  axiosInstance.post(`${config.service.BASE_URL}/${RESET_PASSWORD}`, {
    code,
    password,
    repeatPassword
  });

export const refreshTokens = async (axiosInstace: AxiosInstance, refreshToken: string) => {
  return axiosInstace.post(`${config.service.BASE_URL}/${REFRESH_TOKEN}`, { refreshToken: refreshToken });
};
