import { AxiosInstance, AxiosResponse } from 'axios';
import config from '../../config';
import { IPageableResponse, ICriteria, NewTeamableObject } from '@recone/models';

const GET_FILTERS_PATH = 'criterias/list';
const CREATE_FILTERS_PATH = 'criterias/add';
const UPDATE_FILTERS_PATH = 'criterias/update';
const DELETE_PROJECT_PATH = 'criterias/remove';

export const getCriterias = (
  axiosInstance: AxiosInstance,
  teamId: string,
  page = 0,
  limit = config.service.QUERY_DEFAULT_LIMIT
): Promise<AxiosResponse<IPageableResponse<ICriteria>>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${GET_FILTERS_PATH}?page=${page}&limit=${limit}`, {
    headers: { teamId }
  });

export const createCriteria = (
  axiosInstance: AxiosInstance,
  teamId: string,
  criteria: NewTeamableObject<ICriteria>,
  projectId?: string
): Promise<AxiosResponse<ICriteria>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${CREATE_FILTERS_PATH}`,
    { criteria, projectId },
    { headers: { teamId } }
  );

export const updateCriteria = (
  axiosInstance: AxiosInstance,
  teamId: string,
  criteria: Partial<ICriteria>,
): Promise<AxiosResponse<ICriteria>> =>
  axiosInstance.put(`${config.service.BASE_URL}/${UPDATE_FILTERS_PATH}`,
    { criteria },
    { headers: { teamId } });

export const deleteCriteria = (
  axiosInstance: AxiosInstance,
  teamId: string,
  id: string
): Promise<AxiosResponse<ICriteria>> =>
  axiosInstance.delete(`${config.service.BASE_URL}/${DELETE_PROJECT_PATH}/${id}`, {
    headers: { teamId }
  });
