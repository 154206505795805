const env = process.env;

const config = {
  deviceType: 'web',
  service: {
    BASE_URL: env.REACT_APP_SERVICE_BASE_URL,
    GRAPHQL_URL: env.REACT_APP_GRAPHQL_BASE_URL,
    QUERY_DEFAULT_LIMIT: 25,
    ROW_DATETIME_FORMAT: 'MMM D, YYYY',
    LINKED_IN_URL_PROFILE_PREFIX: 'https://www.linkedin.com/in'
  }
};

export default config;
