import React, { FC } from 'react';
import './ChromeExtensionCommunicator.scss';
import { SESSION_AUTH_REQUEST, SESSION_AUTH_UPDATED } from '@recone/models';
import { useAuth } from '../../contexts/authContext';

interface ChromeExtensionCommunicatorProps {}

const ChromeExtensionCommunicator: FC<ChromeExtensionCommunicatorProps> = ({ children }) => {
  const authData = useAuth();

  const handleAuthDataRequest = () => {
    console.log('dispatching auth data to chrome extension. Token:', authData.token);
    document.dispatchEvent(new CustomEvent(SESSION_AUTH_UPDATED, { detail: authData.token }));
  };

  React.useEffect(() => {
    handleAuthDataRequest();
  }, [authData]);

  React.useEffect(() => {
    document.addEventListener(SESSION_AUTH_REQUEST, handleAuthDataRequest as EventListener);

    // cleanup this component
    return () => {
      document.removeEventListener(SESSION_AUTH_REQUEST, handleAuthDataRequest as EventListener);
    };
  }, []);

  return <>{children}</>;
};

export default ChromeExtensionCommunicator;
