export type IPayload = CategoryDataType[];

export type CategoryDataType = {
  name: string;
  conjuction: 'AND' | 'OR';
  filters: FilterDataType[];
};

export type FilterType =
  | 'multiSelectCreatable'
  | 'multiSelectFromOptions'
  | 'toggleSwitch'
  | 'datePicker'
  | 'number'
  | 'date';

//TODO Refactor this type for be easier to read @dimitrije@totema.co
export type FilterDataType = {
  /**path: root path if target is array */
  path: string;
  name: string;
  filterType: FilterType;
  criteria: CriterionDataType[];

  conjunctionOverridable?: boolean;
  overrideConjunction?: boolean; //used to override default $or behavior inside single filed -- more specifically -> for skills filter we should be able to choose between strict $and and default $or between multiple skills - if set to true, all critera inside the filter will be wrapped with $and, if not - will be wrapped with default $or

  isTimeframe?: boolean;
  past?: boolean;
  timed?: boolean; //used for fileds which are current & past
  valuePath?: string;
  startPath?: string;
  endPath?: string;
  present?: boolean;
} & ( // ) & //   | { isTimeframe: false; valuePath?: never } //   | { isTimeframe: true; valuePath: string } //   | { isTimeframe?: undefined } //  (
    | {
      conjunctionOverridable?: undefined;
    }
    | { conjunctionOverridable: false; overrideConjunction: false }
    | { conjunctionOverridable: true; overrideConjunction: boolean }
  ) &
  (
    | { timed?: undefined }
    | { timed: false; present?: never }
    | {
      timed: true;
      present: boolean;
      valuePath: string;
      startPath: string;
      endPath: string;
    }
    | { timed: true; past: boolean }
  ) &
  (
    | {
      filterType: 'multiSelectCreatable';
      options?: never;
    }
    | ({
      filterType: 'multiSelectFromOptions';
      options: string[];
      isTimeframe?: boolean;
      //used for backend to indicate that it's a timeframe so I can transform those a bit differently - Less Then a Year, between 1 - 3 years, etc
    } & ({ isTimeframe?: undefined } | { isTimeframe: false } | { isTimeframe: true; present: boolean }))
    | {
      filterType: 'toggleSwitch';
      options?: never;
    }
    | {
      filterType: 'datePicker';
      options?: never;
    }
    | {
      filterType: 'number';
      options?: never;
    }
    | {
      filterType: 'date';
      options?: never;
    }
  );

export enum CriterionOperator {
  eq = '=',
  gt = '>',
  gte = '>=',
  lt = '<',
  lte = '<='
}
export type CriterionDataType = {
  value: string;
  included: boolean;
  identifier?: string;
  operator?: CriterionOperator;
};

export type CriteriaAction = (
  categoryName: string,
  filterName: string,
  criteriaValue: string,
  included?: boolean
) => void;

export type AddOrRemoveCriterionAction = (
  categoryName: string,
  filterName: string,
  value: string,
  identifier?: string,
  operator?: CriterionOperator
) => void;

export type ToggleCriterionIncExcAction = (
  categoryName: string,
  filterName: string,
  value: string,
  identifier?: string
) => void;

export type SetCriteriaConjuctionAction = (categoryName: string, filterName: string, conjuction: boolean) => void;

export type HighLightMatches = {
  path: string;
  included: boolean;
  value: string;
  index?: number;
  present?: boolean;
};
