export const numberOfPresent: string[] = ['None', 'One', 'Two or more'];

export const timeframes: string[] = [
  'Less than 1 year',
  '1 to 2 years',
  '3 to 5 years',
  '6 to 10 years',
  'More than 10 years'
];

export const industries: string[] = [
  'Textile Manufacturing',
  'Airlines and Aviation',
  'Hospitals and Health Care',
  'Think Tanks',
  'Wellness and Fitness Services',
  'Civil Engineering',
  'Defense & Space',
  'Telecommunications',
  'Retail',
  'Defense and Space Manufacturing',
  'Real Estate',
  'Chemical Manufacturing',
  'Design Services',
  'Internet Publishing',
  'Conservation Programs',
  'Arts & Crafts',
  'Medical Equipment Manufacturing',
  'Advertising Services',
  'Entertainment',
  'Fine Art',
  'Information Technology & Services',
  'Food and Beverage Manufacturing',
  'Retail Office Equipment',
  'Motor Vehicle Manufacturing',
  'Hospitality',
  'Non-profit Organizations',
  'Oil & Energy',
  'Staffing and Recruiting',
  'Architecture and Planning',
  'Business Content',
  'Retail Apparel and Fashion',
  'Writing and Editing',
  'Book and Periodical Publishing',
  'Higher Education',
  'Civic and Social Organizations',
  'Internet News',
  'E-learning',
  'Biotechnology',
  'Events Services',
  'Newspaper Publishing',
  'Professional Training and Coaching',
  'Nuclear Electric Power Generation',
  'Spectator Sports',
  'Non-profit Organization Management',
  'Armed Forces',
  'Artists and Writers',
  'Libraries',
  'Health, Wellness & Fitness',
  'Renewables & Environment',
  'Consumer Services',
  'Government Administration',
  'Packaging and Containers Manufacturing',
  'Financial Services',
  'Education',
  'IT Services and IT Consulting',
  'Maritime Transportation',
  'Individual and Family Services',
  'Media and Telecommunications',
  'Oil and Gas',
  'Outsourcing and Offshoring Consulting',
  'Program Development',
  'Pharmaceutical Manufacturing',
  'E-Learning Providers',
  'Farming',
  'Legal Services',
  'Marketing Services',
  'Aviation & Aerospace',
  'Law Practice',
  'Travel Arrangements',
  'Human Resources',
  'Market Research',
  'Executive Offices',
  'Banking',
  'Retail Art Supplies',
  'Accounting',
  'Insurance',
  'Construction',
  'Philanthropic Fundraising Services',
  'Software Development',
  'Executive Search Services',
  'Personal Care Product Manufacturing',
  'Research',
  'Security and Investigations',
  'Primary and Secondary Education',
  'Research Services',
  'Paper & Forest Products',
  'Blockchain Services',
  'Aviation and Aerospace Component Manufacturing',
  'Footwear Manufacturing',
  'Technology, Information and Internet',
  'Business Intelligence Platforms',
  'Technology, Information and Media',
  'Oil, Gas, and Mining',
  'Broadcast Media Production and Distribution',
  'Education Administration Programs',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Facilities Services',
  'Biotechnology Research',
  'Renewable Energy Semiconductor Manufacturing',
  'Computer Games',
  'Administrative and Support Services',
  'Holding Companies',
  'Mining',
  'Beverage Manufacturing',
  'Venture Capital and Private Equity Principals',
  'Religious Institutions',
  'Business Consulting and Services',
  'Online Audio and Video Media',
  'Computer and Network Security',
  'Industrial Machinery Manufacturing',
  'Information Services',
  'Blogs',
  'Food & Beverages',
  'Cosmetics',
  'Design',
  'Transportation, Logistics, Supply Chain and Storage',
  'Vocational Rehabilitation Services',
  'Apparel Manufacturing',
  'Alternative Dispute Resolution',
  'Musicians',
  'Food and Beverage Services',
  'Human Resources Services',
  'Media Production',
  'Entertainment Providers',
  'Online Media',
  'Education Management'
];

export const companySizes: string[] = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10000+'
];

export const revenueRanges: string[] = [
  'Less than $1M',
  '$1M to $10M',
  '$10M to $50M',
  '$50M to $100M',
  '$100M to $500M',
  '$500M to $1B',
  '$1B to $10B',
  '$10B+'
];

export const cbCategories: string[] = [
  'Mechanical Design',
  'Flash Sale',
  'Gift',
  'Education',
  'Basketball',
  'Brewing',
  'Mobile Advertising',
  'Flash Storage',
  'Textbook',
  'Embedded Software',
  'Broadcasting',
  'Foundries',
  'Network Security',
  'Audio',
  'Reservations',
  'Millennials',
  'Taxi Service',
  'Quality Assurance',
  'Credit Bureau',
  'SMS',
  'Wildlife Conservation',
  'Leasing',
  'Video Editing',
  'Elder Care',
  'Real Time',
  'Nuclear',
  'Pharmaceutical',
  'Facial Recognition',
  'Desktop Apps',
  'DSP',
  'Data Integration',
  'Management Information Systems',
  'Subscription Service',
  'Search Engine',
  'Carbon Capture',
  'Vacation Rental',
  'Energy',
  'Fraud Detection',
  'Photography',
  'Wired Telecommunications',
  'Market Research',
  'Credit',
  'Wearables',
  'Humanitarian',
  'Home Improvement',
  'Comics',
  'Collection Agency',
  'Nursing and Residential Care',
  'Ports and Harbors',
  'Food and Beverage',
  'Local Business',
  'Music Label',
  'Employee Benefits',
  'Mental Health',
  'Parenting',
  'Special Education',
  'Food Trucks',
  'Biopharma',
  'Wood Processing',
  'Micro Lending',
  'Intelligent Systems',
  'Ferry Service',
  'Enterprise',
  'Domain Registrar',
  'Energy Storage',
  'Music Streaming',
  'NFC',
  'Document Preparation',
  'Freelance',
  'Crowdsourcing',
  'Fertility',
  'Bitcoin',
  'Trade Shows',
  'Embedded Systems',
  'Diving',
  'Blogging Platforms',
  'Addiction Treatment',
  'Chemical Engineering',
  'Fitness',
  'Water',
  'CRM',
  'Ad Network',
  'Impact Investing',
  'Funding Platform',
  'Business Development',
  'Blockchain',
  'Drones',
  'Audio Recording and Production',
  'Therapeutics',
  'Casual Games',
  'Semiconductor',
  'Contests',
  'GreenTech',
  "Men's",
  'Shipping',
  'Collaboration',
  'Social Shopping',
  'Fruit',
  'Sex Industry',
  'Collaborative Consumption',
  'Consumer',
  'Film Distribution',
  'Archiving Service',
  'EdTech',
  'Procurement',
  'Identity Management',
  'Speech Recognition',
  'Data Collection and Labeling',
  'Social Media Marketing',
  'Tobacco',
  'Motorsports',
  'E-Commerce',
  'Internet',
  'Marketing',
  'Serious Games',
  'Oil and Gas',
  'Private Cloud',
  'Chemical',
  'Web Development',
  'File Sharing',
  'Real Estate Investment',
  'Gift Card',
  'Environmental Consulting',
  'Amusement Park and Arcade',
  'Virtual World',
  'Sales Automation',
  'Employment',
  'B2C',
  'Generation Y',
  'Alumni',
  'Service Industry',
  'Business Intelligence',
  'Virtual Assistant',
  'Digital Media',
  'Performing Arts',
  'Foreign Exchange Trading',
  'Seafood',
  'Fossil Fuels',
  'Fuel',
  'Advanced Materials',
  'Data Governance',
  'Translation Service',
  'Task Management',
  'Corrections Facilities',
  'SEM',
  'Pet',
  'Emergency Medicine',
  'Books',
  'Distillery',
  'Green Building',
  'Mobile Payments',
  'Psychology',
  'Skill Assessment',
  'Peer to Peer',
  'Audiobooks',
  'Trading Platform',
  'Table Tennis',
  'Developer APIs',
  'Timeshare',
  'Medical Device',
  'Cosmetics',
  'Point of Sale',
  'IT Infrastructure',
  'Consumer Goods',
  'Charging Infrastructure',
  'Recycling',
  'Travel Agency',
  'Online Auctions',
  'Advocacy',
  'Data Center',
  'Precious Metals',
  'Content',
  'Ad Targeting',
  'Consumer Reviews',
  'First Aid',
  'Google',
  'Higher Education',
  'CAD',
  'Online Forums',
  'Timber',
  'macOS',
  'Craft Beer',
  'Energy Management',
  'Network Hardware',
  'Ethereum',
  'Marine Technology',
  'Health Care',
  'Android',
  'Electric Vehicle',
  'Teenagers',
  'Legal Tech',
  'Public Transportation',
  'Social Entrepreneurship',
  'Mineral',
  'Ad Exchange',
  'Robotics',
  'GPU',
  'Penetration Testing',
  'Cloud Infrastructure',
  'Online Games',
  'Toys',
  'Primary and Urgent Care',
  'Stock Exchanges',
  'Content Marketing',
  'Meat and Poultry',
  'Enterprise Applications',
  'Home Renovation',
  'MOOC',
  'Cyber Security',
  'TV Production',
  'Water Transportation',
  'Genetics',
  'Xbox',
  'Outdoors',
  'Classifieds',
  'Event Promotion',
  'VoIP',
  'Presentation Software',
  'Fleet Management',
  'Precision Medicine',
  'Recruiting',
  'Auto Insurance',
  'Loyalty Programs',
  'CMS',
  'Skiing',
  'Winery',
  'Consumer Applications',
  'Quantified Self',
  'DIY',
  'SEO',
  'Auctions',
  'Rehabilitation',
  'Security',
  'Mining',
  'Home Appliances',
  'Contact Management',
  'Edutainment',
  'Sales Enablement',
  'Secondary Education',
  'Fantasy Sports',
  'Podcast',
  'Spam Filtering',
  'Metaverse',
  'Smart Contracts',
  'Financial Exchanges',
  'Sex Tech',
  'Restaurants',
  'Nightclubs',
  'Electronic Design Automation (EDA)',
  'Indoor Positioning',
  'Reading Apps',
  'Hydroelectric',
  'Equestrian',
  'Social Media',
  'Commercial Insurance',
  'Film Production',
  'Life Insurance',
  'Personal Finance',
  'Personal Branding',
  'Satellite Communication',
  'Business Process Automation (BPA)',
  'Theatre',
  'Web Hosting',
  'Content Discovery',
  'Personalization',
  'Railroad',
  'SNS',
  'Made to Order',
  'Home Services',
  'Consumer Software',
  'Printing',
  'Biotechnology',
  'Limousine Service',
  'Staffing Agency',
  'Environmental Engineering',
  'American Football',
  'Outsourcing',
  'Facebook',
  'Crowdfunding',
  'Transaction Processing',
  'Publishing',
  'SaaS',
  'Tourism',
  'Document Management',
  'Adventure Travel',
  'Multi-level Marketing',
  'Application Performance Management',
  'Recreational Vehicles',
  'App Marketing',
  'Organic Food',
  'Machine Learning',
  'Horticulture',
  'Family',
  'EBooks',
  'Mortgage',
  'Console Games',
  'Funerals',
  'Developer Tools',
  'Marine Transportation',
  'College Recruiting',
  'RFID',
  'Food Delivery',
  'Art',
  'Surfing',
  'Diabetes',
  'RISC',
  'Hospital',
  'Rugby',
  'Nutrition',
  'Shopping',
  'Vending and Concessions',
  'Ticketing',
  'Property Insurance',
  'Electrical Distribution',
  'Shoes',
  'WebOS',
  'Banking',
  'Quantum Computing',
  'Email',
  'Paper Manufacturing',
  'Nightlife',
  "Women's",
  'Politics',
  'Agriculture',
  'Advertising',
  'Creative Agency',
  'Price Comparison',
  'DevOps',
  'Presentations',
  'STEM Education',
  'Reputation',
  'iOS',
  'Debt Collections',
  'Snack Food',
  'Photo Sharing',
  'Digital Entertainment',
  'Sensor',
  'Industrial Manufacturing',
  'Sales',
  'Beauty',
  'QR Codes',
  'Application Specific Integrated Circuit (ASIC)',
  'Social Network',
  'Charter Schools',
  'Lead Management',
  'Alternative Protein',
  'Coworking',
  'Car Sharing',
  'Product Management',
  'Advertising Platforms',
  'Delivery Service',
  'Rental',
  'Military',
  'Jewelry',
  '3D Technology',
  'Unified Communications',
  'Marketplace',
  'Computer',
  'Homeland Security',
  'Cloud Computing',
  'Non Profit',
  'Natural Language Processing',
  'UX Design',
  'Media and Entertainment',
  'Mobile Apps',
  'eSports',
  'Racing',
  'Consumer Research',
  'Management Consulting',
  'Drone Management',
  'Swimming',
  'Mining Technology',
  'Nutraceutical',
  'Tennis',
  'Parking',
  'PC Games',
  'Association',
  'Supply Chain Management',
  'Call Center',
  'Data Management',
  'Ad Retargeting',
  'Q&A',
  'Gambling',
  'Animation',
  'Social',
  'Guides',
  'Rental Property',
  'Emerging Markets',
  'Assistive Technology',
  'Property Development',
  'Ride Sharing',
  'Celebrity',
  'ISP',
  'Generative AI',
  'Meeting Software',
  'Lending',
  'Furniture',
  'Travel',
  'Delivery',
  'Audio/Visual Equipment',
  'TV',
  'Mobile Devices',
  'Office Administration',
  'Travel Accommodations',
  'Smart Home',
  'Credit Cards',
  'Autonomous Vehicles',
  'Augmented Reality',
  'Consumer Electronics',
  'Commercial Real Estate',
  'Aerospace',
  'Farmers Market',
  'Collectibles',
  'Prediction Markets',
  'Health Diagnostics',
  'Data Visualization',
  'Messaging',
  'Angel Investment',
  'Clinical Trials',
  'Recipes',
  'Tax Preparation',
  'CivicTech',
  'Local',
  'Underserved Children',
  'Biometrics',
  'Business Information Systems',
  'Financial Services',
  'Plant-Based Foods',
  'Medical',
  'Event Management',
  'Shipping Broker',
  'Tea',
  'Residential',
  'Building Material',
  'Human Resources',
  'In-Flight Entertainment',
  'Industrial Engineering',
  'Retail Technology',
  'E-Signature',
  'Courier Service',
  'Prepaid Cards',
  'Transportation',
  'Baby',
  'Wedding',
  'Interior Design',
  'Oncology',
  'Vocational Education',
  'Food Processing',
  'Ediscovery',
  'Natural Resources',
  'Social Media Management',
  'Law Enforcement',
  'Content Creators',
  'Personal Health',
  'Risk Management',
  'CleanTech',
  'Analytics',
  'Incubators',
  'Browser Extensions',
  'Electronic Health Record (EHR)',
  'Bookkeeping and Payroll',
  'Handmade',
  'Life Science',
  'Direct Marketing',
  'Digital Marketing',
  'Data Mining',
  'Forestry',
  'Enterprise Resource Planning (ERP)',
  'Wealth Management',
  'Fuel Cell',
  'Lifestyle',
  'Direct Sales',
  'Aquaculture',
  'Windows Phone',
  'Livestock',
  'Robotic Process Automation (RPA)',
  'Wireless',
  'App Discovery',
  'Infrastructure',
  'Sports',
  'Product Design',
  'Ultimate Frisbee',
  'Private Social Networking',
  'Simulation',
  'Small and Medium Businesses',
  'Music Venues',
  'Cycling',
  'Real Estate',
  'Assisted Living',
  'mHealth',
  'PaaS',
  'Intellectual Property',
  'Hunting',
  'National Security',
  'Commercial Lending',
  'MMO Games',
  'Dietary Supplements',
  'Software Engineering',
  'Communication Hardware',
  'A/B Testing',
  'Casino',
  'Computer Vision',
  'Logistics',
  'Consumer Lending',
  'Local Advertising',
  'Concerts',
  'Secondhand Goods',
  'Water Purification',
  'Twitter',
  'Geospatial',
  'Soccer',
  'Sailing',
  'Shopping Mall',
  'Bioinformatics',
  'Freight Service',
  'Semantic Web',
  'Personal Care and Hygiene',
  'Darknet',
  'Facility Management',
  'Web Browsers',
  'Accounting',
  'Visual Search',
  'Ad Server',
  'Cloud Management',
  'Finance',
  '3D Printing',
  'Resorts',
  'Apps',
  'Cosmetic Surgery',
  'Telehealth',
  'Gamification',
  'Image Recognition',
  'Sporting Goods',
  'Business Travel',
  'Parks',
  'Legal',
  'Video Streaming',
  'Cause Marketing',
  'Virtual Goods',
  'Data Storage',
  'Flowers',
  'Web Apps',
  'Venture Capital',
  'Commercial',
  'Remote Sensing',
  'GPS',
  'Baseball',
  'Cloud Storage',
  'Outdoor Advertising',
  'Social Recruiting',
  'Last Mile Transportation',
  'Vertical Search',
  'Space Travel',
  'Confectionery',
  'Children',
  'Retail',
  'Card and Board Games',
  'Virtual Workforce',
  'Wind Energy',
  'Insurance',
  'Dating',
  'IT Management',
  'Social Assistance',
  'Location Based Services',
  'IaaS',
  'Consulting',
  'GovTech',
  'Content Delivery Network',
  'Hydroponics',
  'Coffee',
  'Semantic Search',
  'Automotive',
  'Debit Cards',
  'Sponsorship',
  'Communities',
  'Elderly',
  'Recreation',
  'Wholesale',
  'Cryptocurrency',
  'Cleaning Products',
  'Professional Networking',
  'Organic',
  'Health Insurance',
  'Marketing Automation',
  'Digital Signage',
  'Janitorial Service',
  'Hardware',
  'Social Impact',
  'Video Chat',
  'Hedge Funds',
  'Facilities Support Services',
  'Software',
  'Optical Communication',
  'Laundry and Dry-cleaning',
  'Self-Storage',
  'Developer Platform',
  'Knowledge Management',
  'Hotel',
  'Physical Security',
  'E-Commerce Platforms',
  'Film',
  'Big Data',
  'Solar',
  'Corporate Training',
  'Wellness',
  'Career Planning',
  'Volley Ball',
  'Artificial Intelligence (AI)',
  'Decentralized Finance (DeFi)',
  'Housekeeping Service',
  'Field Support',
  'Database',
  'Video',
  'Lingerie',
  'Non-Fungible Token (NFT)',
  'Journalism',
  'Smart Building',
  'Property Management',
  'Building Maintenance',
  'Virtual Desktop',
  'Advice',
  'Home Decor',
  'Local Shopping',
  'Predictive Analytics',
  'Wine And Spirits',
  'Animal Feed',
  'Charity',
  'Tour Operator',
  'Machinery Manufacturing',
  'Fast-Moving Consumer Goods',
  'Smart Cities',
  'Playstation',
  'Personal Development',
  'Laser',
  'Government',
  'Cloud Data Services',
  'Warehousing',
  'Fashion',
  'Musical Instruments',
  'Plastics and Rubber Manufacturing',
  'Extermination Service',
  'Civil Engineering',
  'Nanotechnology',
  'Brand Marketing',
  'Scheduling',
  'Mapping Services',
  'Enterprise Software',
  'LGBT',
  'Dairy',
  'Public Relations',
  'Navigation',
  'Events',
  'Music Education',
  'Same Day Delivery',
  'Office Supplies',
  'Cannabis',
  'Museums and Historical Sites',
  'B2B',
  'Air Transportation',
  'Universities',
  'Asset Management',
  'Music',
  'Linux',
  'Hockey',
  'Online Portals',
  'Operating Systems',
  'Bakery',
  'Dental',
  'Compliance',
  'Windows',
  'Video Games',
  'Google Glass',
  'Outpatient Care',
  'News',
  'Project Management',
  'Cloud Security',
  'PropTech',
  'Information Technology',
  'Herbs and Spices',
  'Eyewear',
  'Group Buying',
  'Professional Services',
  'Architecture',
  'Gaming',
  'Billing',
  'Internet Radio',
  'Affiliate Marketing',
  'Language Learning',
  'Web Design',
  'Usability Testing',
  'Video Conferencing',
  'Home and Garden',
  'Social CRM',
  'Gift Exchange',
  'Retirement',
  'Religion',
  'FinTech',
  'Franchise',
  'Real Estate Brokerage',
  'Video Advertising',
  'Telecommunications',
  'Electronics',
  'Adult',
  'Privacy',
  'Young Adults',
  'Information and Communications Technology (ICT)',
  'Farming',
  'Industrial Design',
  'InsurTech',
  'Graphic Design',
  'Neuroscience',
  'Sustainability',
  'Roku',
  'Golf',
  'Construction',
  'Independent Music',
  'Catering',
  'Renewable Energy',
  'Video on Demand',
  'Training',
  'Cooking',
  'Social News',
  'Biofuel',
  'Energy Efficiency',
  'Sharing Economy',
  'Clean Energy',
  'Chatbot',
  'Text Analytics',
  'Hospitality',
  'Manufacturing',
  'Tutoring',
  'E-Learning',
  'Apparel',
  'Mobile',
  'Pollution Control',
  'Lighting',
  'Intrusion Detection',
  'Social Media Advertising',
  'Test and Measurement',
  'Tax Consulting',
  'Waste Management',
  'Biomass Energy',
  'Virtualization',
  'Open Source',
  'Packaging Services',
  'Grocery',
  'Data Center Automation',
  'Nintendo',
  'Internet of Things',
  'Innovation Management',
  'Virtual Currency',
  'Primary Education',
  'Public Safety',
  'Power Grid',
  'Social Bookmarking',
  'Generation Z',
  'Industrial',
  'Veterinary',
  'Product Search',
  'Heating, Ventilation, and Air Conditioning (HVAC)',
  'Copywriting',
  'Textiles',
  'DRM',
  'Product Research',
  'Communications Infrastructure',
  'Boating',
  'Coupons',
  'Green Consumer Goods',
  'Continuing Education',
  'Field-Programmable Gate Array (FPGA)',
  'Motion Capture',
  'Virtual Reality',
  'Leisure',
  'Content Syndication',
  'Productivity Tools',
  'Cricket',
  'Information Services',
  'Payments',
  'Native Advertising',
  'Child Care',
  'Battery',
  'Gift Registry',
  'Email Marketing',
  'Freemium',
  'Mechanical Engineering',
  'Sports Leagues and Teams',
  'Photo Editing',
  'Technical Support',
  'Homeless Shelter',
  'Industrial Automation',
  'Tizen',
  'AgTech',
  'Warehouse Automation',
  'Geothermal Energy',
  'Human Computer Interaction',
  'Customer Service',
  'Web3',
  'Alternative Medicine',
  'Home Health Care',
  'Landscaping',
  'Lead Generation'
];

export const cbCategoryGroups: string[] = [
  'Sports',
  'Artificial Intelligence',
  'Artificial Intelligence (AI)',
  'Energy',
  'Blockchain and Cryptocurrency',
  'Music and Audio',
  'Sales and Marketing',
  'Lending and Investments',
  'Platforms',
  'Food and Beverage',
  'Real Estate',
  'Manufacturing',
  'Apps',
  'Natural Resources',
  'Science and Engineering',
  'Agriculture and Farming',
  'Education',
  'Gaming',
  'Media and Entertainment',
  'Content and Publishing',
  'Data and Analytics',
  'Financial Services',
  'Community and Lifestyle',
  'Consumer Electronics',
  'Administrative Services',
  'Messaging and Telecommunications',
  'Navigation and Mapping',
  'Professional Services',
  'Social Impact',
  'Payments',
  'Government and Military',
  'Advertising',
  'Events',
  'Biotechnology',
  'Consumer Goods',
  'Artificial Intelligence (AI)',
  'Sustainability',
  'Privacy and Security',
  'Information Technology',
  'Video',
  'Software',
  'Health Care',
  'Clothing and Apparel',
  'Internet Services',
  'Mobile',
  'Design',
  'Other',
  'Hardware',
  'Travel and Tourism',
  'Commerce and Shopping',
  'Transportation'
];

export const fundingTypeOptions: string[] = [
  'series_a',
  'equity_crowdfunding',
  'series_unknown',
  'post_ipo_equity',
  'series_f',
  'series_h',
  'convertible_note',
  'series_e',
  'series_i',
  'pre_seed',
  'corporate_round',
  'angel',
  'debt_financing',
  'private_equity',
  'grant',
  'series_b',
  'series_d',
  'post_ipo_debt',
  'post_ipo_secondary',
  'secondary_market',
  'seed',
  'undisclosed',
  'non_equity_assistance',
  'initial_coin_offering',
  'series_c',
  'series_g',
  'product_crowdfunding'
];

export const specialities: string[] = [
  /**to be filled later */
];

export const linkedinCompanyTypeOptions: string[] = [
  'Public Company',
  'Privately Held',
  'Non Profit',
  'Educational Institution',
  'Partnership',
  'Self Employed',
  'Self Owned',
  'Government Agency'
];
