import config from '../../config';
import { useAxios } from '../useAxios';
import { useTeamId } from './auth';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { createCriteria, deleteCriteria, getCriterias, updateCriteria } from '../../services/api/criteria';
import { ICriteria, NewTeamableObject } from '@recone/models';

export const GET_CRITERIAS_QUERY_ID = 'criterias/list';

export function useGetCriterias(limit = config.service.QUERY_DEFAULT_LIMIT) {
  const axios = useAxios();
    const teamId = useTeamId();

  return useInfiniteQuery([GET_CRITERIAS_QUERY_ID], ({ pageParam }) => getCriterias(axios, teamId, pageParam, limit), {
    enabled: !!teamId,
    getNextPageParam: (lastData, pages) => pages.length,
  });
}

export function useCreateCriteria(projectId?: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { criteria: NewTeamableObject<ICriteria> }) => createCriteria(axios, teamId, args.criteria, projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CRITERIAS_QUERY_ID]).then();
      }
    }
  );
}

export function useUpdateCriteria() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((args: { criteria: Partial<ICriteria> }) => updateCriteria(axios, teamId, args.criteria), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CRITERIAS_QUERY_ID]).then();
    }
  });
}

export function useDeleteCriteria() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((args: { criteriaId: string }) => deleteCriteria(axios, teamId, args.criteriaId), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CRITERIAS_QUERY_ID]).then();
    }
  });
}
