export enum RefreshTypeEnum {
  Profile = 'Profile',
  Company = 'Company'
}

export type RefreshItem = {
  _id: string;
  status: RefreshStatus;
  name: string;
  url: string;
};

export enum RefreshStatus {
  PENDING,
  DONE,
  FAILED
}

export enum RefreshCompaniesFromProjectEnum {
  Current,
  All,
  Project
}
