import React, { FC, useState } from 'react';
import './ForgotPassword.scss';
import Grid from '@mui/material/Grid';
import { Field, Form, Formik } from 'formik';
import AuthCard from '../../components/AuthCard';
import { TextField } from 'formik-mui';
import Button from '../../components/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useForgotPassword } from '../../hooks/api/auth';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import { CountdownTimeDelta } from 'react-countdown/dist/utils';

const ForgotPassSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
});

interface ForgotPasswordProps {
}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const forgotPassword = useForgotPassword();

  const [step, setStep] = useState(1);
  const [userEmail, setUserEmail] = useState('');
  const [isResendEmailClicked, setIsResendEmailClicked] = useState(false);
  const [resendExpirationTime, setResendExpirationTime] = useState(Number.parseInt(process.env['REACT_APP_RESET_PASSWORD_EXPIRE_TIME'] as string) * 60000);

  const handleForgotPassword = (email: string, isSubmitting?: (isSubmitting: boolean) => void) => {
    forgotPassword.mutate(
      { email: email },
      {
        onSuccess: (res) => {
          setStep(2);
          if (isSubmitting) {
            isSubmitting(false);
          } else {
            setIsResendEmailClicked(false);
            toast.success(res.data.payload.message);
          }
        },
        onError: (error: any) => {
          if (isSubmitting) {
            isSubmitting(false);
          } else {
            setIsResendEmailClicked(false);
          }
          toast.error(error?.response?.data?.message ?? 'Error sending email, please try again...');
        }
      }
    );
  }

  const handleOnSubmit = (values: { email: string }, isSubmitting: (isSubmitting: boolean) => void) => {
    setUserEmail(values.email);
    handleForgotPassword(values.email, isSubmitting);
  };

  const renderer = ({ completed, minutes, seconds }: CountdownTimeDelta) => {
    return !completed ? (
      <Typography sx={{ marginBottom: '25px' }} variant='subtitle1'>
        wait <strong>{minutes}:{seconds} minutes</strong> to resend code again.
      </Typography>
    ) : (
      <Button
        className='AuthFormBtn'
        color='primary'
        disabled={isResendEmailClicked}
        endIcon={isResendEmailClicked && <CircularProgress size={21} />}
        fullWidth
        size='large'
        onClick={() => {
          handleForgotPassword(userEmail);
          setIsResendEmailClicked(true);
          setResendExpirationTime(
            Number.parseInt(process.env['REACT_APP_RESET_PASSWORD_EXPIRE_TIME'] as string) * 60000
          );
        }}>
        Send link again
      </Button>
    );
  };

  let cardContainer = (
    <>
      <Typography mb={1} variant='h6'>
        We sent a reset password link to your email!
      </Typography>
      <Typography variant='subtitle1'>The reset link will expire after 30 minutes.</Typography>
      <Typography variant='subtitle1'>Did not receive the email? Check your spam filter or</Typography>
      <Countdown date={Date.now() + resendExpirationTime} renderer={renderer} zeroPadTime={0} />
      <Typography textAlign='center' variant='subtitle1'>
        <NavLink to='login'>Back to Sign in</NavLink>
      </Typography>
    </>
  );

  if (isResendEmailClicked) {
    cardContainer = <CircularProgress sx={{ margin: 'auto', display: 'flex' }} />;
  }

  return (
    <>
      <Grid className='AuthContainer ForgotPassword' container data-testid='ForgotPassword' flexGrow={1} xs={12}>
        {step === 1 && (
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={ForgotPassSchema}
            onSubmit={(values, { setSubmitting }) => handleOnSubmit(values, setSubmitting)}>
            {({ errors, isSubmitting, resetForm, submitForm, touched, values }) => (
              <AuthCard
                header='Forgot Password'
                subHeader={
                  'Enter your email address below, and we’ll send you instructions to reset it.'
                }>
                <Form className='AuthForm'>
                  <Field
                    component={TextField}
                    fullWidth
                    id='email-input'
                    label='Email'
                    margin={'dense'}
                    name='email'
                    type='text'
                  />
                  <Button
                    className='AuthFormBtn'
                    color='primary'
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    endIcon={isSubmitting && <CircularProgress size={21} />}
                    fullWidth
                    type='submit'>
                    Send Reset Link
                  </Button>
                  <Typography variant='subtitle1'>
                    <NavLink to='login'>Back to Sign in</NavLink>
                  </Typography>
                </Form>
              </AuthCard>
            )}
          </Formik>
        )}
        {step === 2 && <AuthCard showTittle={false}>{cardContainer}</AuthCard>}
      </Grid>
    </>
  );
};
export default ForgotPassword;
