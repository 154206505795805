export const SESSION_AUTH_UPDATED = 'SharebirdAuthDataUpdated';
export const SESSION_AUTH_REQUEST = 'SharebirdAuthDataRequest';

export type SessionAuthData = { token: string };

declare global {
  interface DocumentEventMap {
    SESSION_AUTH_UPDATED: CustomEvent<SessionAuthData>;
    SESSION_AUTH_REQUEST: CustomEvent;
  }
}
