import React from 'react';
import './App.css';
import { Router } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundaries';
import { ToastContainer } from 'react-toastify';
import { history } from 'helpers/common';
import Routes from './routes';
import AxiosProvider from './contexts/axiosContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from './styles/Theme/Theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.min.css';
import { AuthProvider } from './contexts/authContext';
import ChromeExtensionCommunicator from './components/ChromeExtensionCommunicator';
import { LicenseInfo } from '@mui/x-license-pro';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import './styles/Theme/global.styles.scss';
import { GraphQlRequestProvider } from './contexts/graphqlRequestClientContext';
import { MainLayoutProvider } from './contexts/mainLayoutContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FilterProvider } from 'contexts/filtersContext';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENCE ?? '');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <AuthProvider>
      <FlagsmithProvider
        options={{
          environmentID: process.env.REACT_APP_FEATURE_FLAG_ENV_ID || ''
        }}
        flagsmith={flagsmith}>
        <ChromeExtensionCommunicator>
          <AxiosProvider>
            <GraphQlRequestProvider>
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CssBaseline enableColorScheme />
                    <FilterProvider>
                      <MainLayoutProvider>
                        <div className="App">
                          <ToastContainer autoClose={5000} position="top-center" />
                          <Router history={history}>
                            <ErrorBoundary>
                              <Routes />
                            </ErrorBoundary>
                          </Router>
                        </div>
                      </MainLayoutProvider>
                    </FilterProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </QueryClientProvider>
            </GraphQlRequestProvider>
          </AxiosProvider>
        </ChromeExtensionCommunicator>
      </FlagsmithProvider>
    </AuthProvider>
  );
}

export default App;
