import React, { FC } from 'react';
import './SequenceBreadCrumbs.scss';
import { useGetSequenceOverview } from '../../../hooks/api/sequences';
import { ISequenceNew } from '@recone/models';

interface SequenceBreadCrumbsProps {
  match?: any;
}

const SequenceBreadCrumbs: FC<SequenceBreadCrumbsProps> = ({ match }) => {
  const { sequenceId } = match?.params ?? '';

  const { data: sequenceData } = useGetSequenceOverview(sequenceId);

  return <>{(sequenceData?.data.sequenceRef as ISequenceNew)?.name}</>;
};

export default SequenceBreadCrumbs;
