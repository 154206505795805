import React from 'react';
import './Image.scss';

interface ImageProps {
  src?: string;
  imgClassName?: string;
}

const Image = (props: ImageProps) => {
  const { imgClassName, src } = props;

  return (
    <div className="Image" data-testid="Image">
      <img alt='logo' className={imgClassName} src={src} />
    </div>
  );
};

export default Image;
