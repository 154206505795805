import { createBrowserHistory } from 'history';
import config from '../config';
import datamask from 'datamask';
import dayjs from 'dayjs';
import { passwordStrength } from 'check-password-strength';

export const history = createBrowserHistory();

export const formatISODateTime = (date: string): string => dayjs(date).format(config.service.ROW_DATETIME_FORMAT);

export const maskEmail = (email: string): string => datamask.email(email);

export const percentCalculator = (total = 0, value = 0) => {
  if (total === 0) {
    return 0;
  }

  return ((value / total) * 100).toFixed(0);
};

export const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const objectToParams = (params: { [key: string]: string | Array<string> } = {}): string => {
  const tmp = Object.keys(params).map((key) => {
    if (Array.isArray(params[key])) {
      const subTmp = (params[key] as Array<string>).map((value: string | Array<string>) => {
        return objectToParams({ [key]: value });
      });

      return subTmp.join('&');
    }

    return `${key}=${params[key]}`;
  });

  return tmp.join('&');
};

export const checkPasswordStrength = (values?: string) => {
  if (values) {
    return passwordStrength(values);
  }
};

export const stringEnumToArray = (enumType: any): { key: string; value: string }[] => {
  return Object.keys(enumType).map((key) => ({ key, value: enumType[key] }));
};
