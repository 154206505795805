export interface ISubscription {
  subscriptionId?: string;
  checkoutSessionId?: string;
  checkoutInitDate: Date;
  status: ESubscriptionStatus;
  validUntil?: Date;
}

export enum ESubscriptionStatus {
  ACTIVE = 'active',
  CANCELED_BY_USER = 'canceled_by_user',
  EXPIRED = 'expired',
  CANCELING = 'cancelling',
  PROCESSING = 'processing',
  TRIAL = 'trial',
  FREE = 'free'
}
