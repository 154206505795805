import { AxiosInstance, AxiosResponse } from 'axios';
import config from '../../config';

const ASSIGN_TO_PROJECT = 'crawled-accounts/assignToProject';
const REMOVE_FROM_PROJECT = 'crawled-accounts/removeFromProject';

export const assignToSavedTab = (
  axiosInstance: AxiosInstance,
  teamId: string,
  accountId: string,
  projectId: string
): Promise<AxiosResponse<boolean>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${ASSIGN_TO_PROJECT}`,
    { projectId, accountId },
    {
      headers: { teamId }
    }
  );

export const removeFromProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  accountId: string,
  projectId: string
): Promise<AxiosResponse<boolean>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${REMOVE_FROM_PROJECT}`,
    { projectId, accountId },
    {
      headers: { teamId }
    }
  );
