import { FC, Suspense } from 'react';
import ROUTES, { RenderRoutes } from './RenderRoutes';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export const Routes: FC<{}> = () => (
  <Suspense
    fallback={
      <Grid alignItems="center" container direction="column" justifyContent="center" sx={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    }
  >
    <RenderRoutes routes={ROUTES} />
  </Suspense>
);
