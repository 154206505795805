import React, { FC, useState } from 'react';
import './ResetPassword.scss';
import Grid from '@mui/material/Grid';
import { Field, Form, Formik } from 'formik';
import AuthCard from '../../components/AuthCard';
import { TextField } from 'formik-mui';
import Button from '../../components/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import PasswordStrengthIndicator, {
  PasswordStrength
} from '../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { toast } from 'react-toastify';
import { checkPasswordStrength } from '../../helpers/common';
import TogglePasswordVisibility from '../../components/TogglePasswordVisibility';
import { useLocation } from 'react-router';
import { useResetPassword } from '../../hooks/api/auth';

const ResetPassSchema = Yup.object().shape({
  password: Yup.string().required('Required').min(8, 'Password is not valid'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords don\'t match!')
    .required('Required')
});

interface ResetPasswordProps {

}

const ResetPassword: FC<ResetPasswordProps> = () => {
  const [step, setStep] = useState(1);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const resetPassword = useResetPassword();

  const initialPassStrengthState = {
    contains: [],
    length: 0,
    id: 0,
    value: ''
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passStrength, setPassStrength] = useState<PasswordStrength>(initialPassStrengthState);

  const handleOnSubmit = (
    values: { password: string; repeatPassword: string },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    resetPassword.mutate(
      {
        password: values.password,
        repeatPassword: values.repeatPassword,
        code: params.get('code') as string
      },
      {
        onSuccess: () => {
          setSubmitting(false);
          setStep(2);
        },
        onError: (error: any) => {
          setSubmitting(false);
          toast.error(error?.response?.data?.payload.message ?? 'Error resetting password, please try again...');
        }
      }
    );
  };

  return (
    <>
      <Grid className='AuthContainer ResetPassword' container data-testid='ResetPassword' flexGrow={1} xs={12}>
        {step === 1 && (
          <Formik
            initialValues={{
              password: '',
              repeatPassword: ''
            }}
            validationSchema={ResetPassSchema}
            validate={async (values) => {
              if (values.password !== '') {
                const data = checkPasswordStrength(values.password);
                if (data) {
                  return setPassStrength(data);
                }
                return {};
              } else {
                return setPassStrength(initialPassStrengthState);
              }
            }}
            onSubmit={(values, { setSubmitting }) => handleOnSubmit(values, setSubmitting)}>
            {({ errors, isSubmitting, resetForm, submitForm, touched, values }) => (
              <AuthCard
                header='Reset your password'
              >
                <Form className='AuthForm'>
                  <Field
                    InputProps={{
                      endAdornment: (
                        <TogglePasswordVisibility
                          showPassword={showPassword}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )
                    }}
                    component={TextField}
                    fullWidth
                    id='password-input'
                    label='Password'
                    margin='dense'
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                  />
                  <PasswordStrengthIndicator password={passStrength}></PasswordStrengthIndicator>
                  <Field
                    InputProps={{
                      endAdornment: (
                        <TogglePasswordVisibility
                          showPassword={showConfirmPassword}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      )
                    }}
                    component={TextField}
                    fullWidth
                    id='confirm-password-input'
                    label='Confirm password'
                    margin='dense'
                    name='repeatPassword'
                    type={showConfirmPassword ? 'text' : 'password'}
                  />
                  <Button
                    className='AuthFormBtn'
                    color='primary'
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    endIcon={isSubmitting && <CircularProgress size={21} />}
                    fullWidth
                    size='large'
                    type='submit'>
                    Reset
                  </Button>
                  <Typography variant='subtitle1'>
                    <NavLink to='login'>Back to Sign in</NavLink>
                  </Typography>
                </Form>
              </AuthCard>
            )}
          </Formik>
        )}
        {step === 2 && (
          <AuthCard showTittle={false}>
            <Typography variant='h6' mb={1}>Successful password reset!</Typography>
            <Typography variant='subtitle1'>You can now use your new password to sign in to your account.</Typography>
            <Button size='large' color='primary' fullWidth className='AuthFormBtn'>
              <NavLink to='login' style={{color: 'white', textDecoration: 'none'}}>Back to Sign in</NavLink>
            </Button>
          </AuthCard>
        )}
      </Grid>
    </>
  );
};

export default ResetPassword;
