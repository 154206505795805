import { AxiosInstance, AxiosResponse } from 'axios';
import config from '../../config';
import {
  IPageableResponse,
  IProject,
  ISequenceDetailOverview,
  ISequenceNew,
  ISequenceOverview,
  IStage,
  ThreadData
} from '@recone/models';

const GET_SEQUENCES_PATH = 'sequence/list/overview';
const DELETE_SEQUENCE_PATH = 'sequence/remove-sequence';
const GET_SEQUENCE_OVERVIEW = 'sequence/overview';
const GET_SEQUENCE = 'sequence/byId';
const CREATE_SEQUENCES_PATH = 'sequence/add/with-stages ';
const UPDATE_SEQUENCE_PATH = 'sequence/update';
const SEQUENCE_NAME_EXISTS_PATH = 'sequence/doesSeqExist';
const GET_THREAD_MESSAGES = 'sequence/overview/:sequenceId/inbox/:mapId';
const REPLY_MESSAGE = 'user-project-map/:mapItem/reply/:messageId';
const MARK_AS_READ_PATH = 'user-project-map/markAsRead';

export type StagesInfoType = IStage & { afterDays?: number };

export const getSequences = (
  axiosInstance: AxiosInstance,
  teamId: string,
  page = 0,
  limit = config.service.QUERY_DEFAULT_LIMIT
): Promise<AxiosResponse<IPageableResponse<ISequenceOverview>>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${GET_SEQUENCES_PATH}?page=${page}&limit=${limit}`, {
    headers: { teamId }
  });

export const createSequenceWithStages = (
  axiosInstance: AxiosInstance,
  teamId: string,
  name: string,
  stagesInfo: StagesInfoType[]
): Promise<AxiosResponse<ISequenceNew>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${CREATE_SEQUENCES_PATH}`,
    {
      name,
      stagesInfo: stagesInfo.map((stageInfo) => {
        const { afterDays, ...stage } = stageInfo;

        return { stage: stage, afterDays: afterDays };
      })
    },
    { headers: { teamId } }
  );

export const deleteSequence = (
  axiosInstance: AxiosInstance,
  teamId: string,
  sequenceId: string
): Promise<AxiosResponse<ISequenceOverview>> =>
  axiosInstance.delete(`${config.service.BASE_URL}/${DELETE_SEQUENCE_PATH}/${sequenceId}`, {
    headers: { teamId }
  });

export function getSequenceOverview(
  axiosInstance: AxiosInstance,
  teamId: string,
  sequenceId: string
): Promise<AxiosResponse<ISequenceDetailOverview>> {
  return axiosInstance.get(`${config.service.BASE_URL}/${GET_SEQUENCE_OVERVIEW}/${sequenceId}`, {
    headers: { teamId }
  });
}

export function getSequence(
  axiosInstance: AxiosInstance,
  teamId: string,
  sequenceId: string
): Promise<AxiosResponse<ISequenceNew>> {
  return axiosInstance.get(`${config.service.BASE_URL}/${GET_SEQUENCE}/${sequenceId}`, { headers: { teamId } });
}

export const updateSequence = (
  axiosInstance: AxiosInstance,
  teamId: string,
  sequence: Partial<ISequenceNew>
): Promise<AxiosResponse<ISequenceNew>> =>
  axiosInstance.put(
    `${config.service.BASE_URL}/${UPDATE_SEQUENCE_PATH}`,
    { sequence: sequence },
    { headers: { teamId } }
  );

export const doesSequenceNameExist = (
  axiosInstance: AxiosInstance,
  teamId: string,
  name: string
): Promise<AxiosResponse> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${SEQUENCE_NAME_EXISTS_PATH}`,
    {
      name
    },
    { headers: { teamId } }
  );

export function getThreadMessages(
  axiosInstance: AxiosInstance,
  teamId: string,
  sequenceId: string,
  mapId: string
): Promise<AxiosResponse<ThreadData[]>> {
  const url = GET_THREAD_MESSAGES.replace(':sequenceId', sequenceId).replace(':mapId', mapId);
  return axiosInstance.get(`${config.service.BASE_URL}/${url}`, { headers: { teamId } });
}

export const replyMessage = (
  axiosInstance: AxiosInstance,
  teamId: string,
  mapItemId: string,
  messageId: string,
  reply: { body: string }
): Promise<AxiosResponse> => {
  const url = REPLY_MESSAGE.replace(':mapItem', mapItemId).replace(':messageId', messageId);

  return axiosInstance.post(
    `${config.service.BASE_URL}/${url}`,
    {
      message: reply
    },
    { headers: { teamId } }
  );
};

export const markAsRead = (
  axiosInstance: AxiosInstance,
  teamId: string,
  mapItemId: string
): Promise<AxiosResponse<ISequenceNew>> =>
  axiosInstance.post(`${config.service.BASE_URL}/${MARK_AS_READ_PATH}/${mapItemId}`, {}, { headers: { teamId } });
