import React from 'react';
import './TogglePasswordVisibility.scss';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface TogglePasswordVisibilityProps {
  onClick?: () => void;
  showPassword: boolean;
}

function TogglePasswordVisibility(props: TogglePasswordVisibilityProps) {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" className="TogglePasswordVisibility" onClick={props.onClick}>
        {props.showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
}

export default TogglePasswordVisibility;
