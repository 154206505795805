import config from '../../config';
import { useAxios } from '../useAxios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteSequence,
  getSequenceOverview,
  getSequences,
  createSequenceWithStages,
  StagesInfoType,
  getSequence,
  updateSequence,
  doesSequenceNameExist,
  getThreadMessages,
  replyMessage,
  markAsRead
} from '../../services/api/sequences';
import { IProject, ISequenceNew, IStage } from '@recone/models';
import { updateProject } from '../../services/api/projects';
import { useTeamId } from './auth';

const GET_SEQUENCES_QUERY_ID = 'sequences';
const GET_SEQUENCE_EDIT = 'edit';
const GET_THREAD_MESSAGES = 'inbox';

export const useGetSequences = (limit = config.service.QUERY_DEFAULT_LIMIT) => {
  const axios = useAxios();
  const teamId = useTeamId();

  return useInfiniteQuery([GET_SEQUENCES_QUERY_ID], ({ pageParam }) => getSequences(axios, teamId, pageParam, limit), {
    enabled: !!teamId,
    getNextPageParam: (lastData, pages) => pages.length
  });
};

export const useCreateSequence = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { name: string; stages: StagesInfoType[] }) =>
      createSequenceWithStages(axios, teamId, args.name, args.stages),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SEQUENCES_QUERY_ID]).then();
      }
    }
  );
};

export function useGetSequenceOverview(sequence: string) {
  const axios = useAxios();
  const teamId = useTeamId();

  return useQuery([GET_SEQUENCES_QUERY_ID, sequence], () => getSequenceOverview(axios, teamId, sequence), {
    enabled: !!teamId && !!sequence
  });
}

export function useGetSequence(sequenceId?: string) {
  const axios = useAxios();
  const teamId = useTeamId();

  return useQuery(
    [GET_SEQUENCES_QUERY_ID, GET_SEQUENCE_EDIT, sequenceId],
    () => {
      if (!sequenceId) {
        throw 'No sequence id provided';
      }

      return getSequence(axios, teamId, sequenceId);
    },
    {
      enabled: !!teamId && !!sequenceId
    }
  );
}

export function useDeleteSequence() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((args: { sequenceId: string }) => deleteSequence(axios, teamId, args.sequenceId), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SEQUENCES_QUERY_ID]).then();
    }
  });
}

export function useUpdateSequence() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { sequence: Partial<ISequenceNew> }) => {
      if (!axios) {
        throw 'Axios instance is null';
      }

      return updateSequence(axios, teamId, args.sequence);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SEQUENCES_QUERY_ID]).then();
      }
    }
  );
}

export const useDoesSequenceNameExist = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((args: { name: string }) => doesSequenceNameExist(axios, teamId, args.name));
};

export const useGetThreadMessages = (sequenceId?: string, mapId?: string) => {
  const axios = useAxios();
  const teamId = useTeamId();

  return useQuery([GET_THREAD_MESSAGES, mapId], () => getThreadMessages(axios, teamId, sequenceId!, mapId!), {
    enabled: !!teamId && !!sequenceId && !!mapId
  });
};

export const useSendReply = (mapItemId?: string) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { messageToReplyId: string; replyMessage: { body: string } }) => {
      if (!axios) {
        throw 'Axios instance is null';
      }

      if (!mapItemId) {
        throw new Error('No map id provided');
      }

      return replyMessage(axios, teamId, mapItemId, args.messageToReplyId, args.replyMessage);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_THREAD_MESSAGES, mapItemId]).then();
      }
    }
  );
};

export const useMarkAsRead = (mapItemId?: string, sequenceId?: string) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    () => {
      if (!axios) {
        throw 'Axios instance is null';
      }

      if (!mapItemId) {
        throw new Error('No map id provided');
      }

      return markAsRead(axios, teamId, mapItemId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SEQUENCES_QUERY_ID, sequenceId]).then();
      }
    }
  );
};
