export type StageType = 'email';

/**
 * Represents one stage in sequence
 * Translated to normal language - it is one email to be sent
 */
export interface IStage {
  /**
   * primary key
   */
  _id: string;

  /**
   * Name of the stage - not really relevant, it can be something like 'stage 0', 'stage 1', 'stage 2'
   */
  name: string;

  /**
   * Email subject
   */
  title: string;

  /**
   * Email body
   */
  description: string;

  /**
   * Type of the stage. Currently, it is only email, but later it can be inmail, li-mail etc
   */
  stageType: StageType;
}

export const IsStageValid = (stage: IStage): boolean => {
  return !!stage.name && !!stage.title && !!stage.description && stage.stageType == 'email';
};
