import { createContext, useState, PropsWithChildren, Dispatch, SetStateAction } from 'react';
import { initialFilters } from 'components/FiltersComponent/initial-filters';
import { CategoryDataType } from '@recone/models';

interface IFilterContext {
  categories?: CategoryDataType[];
  setCategories?: Dispatch<SetStateAction<CategoryDataType[]>>;
}

const FilterContext = createContext<IFilterContext>({});

const FilterProvider = ({ children }: PropsWithChildren<{}>) => {
  const [categories, setCategories] = useState<CategoryDataType[]>(JSON.parse(JSON.stringify(initialFilters))); // it's dirty fix, but we need it

  return (
    <FilterContext.Provider
      value={{
        categories,
        setCategories
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterProvider };
