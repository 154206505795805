import { GraphQLClient } from 'graphql-request';
import { createContext, PropsWithChildren, useMemo } from 'react';
import { useAuth } from './authContext';
import config from '../config';

const BACKEND_RESPONSE_ACCESS_TOKEN = 'x-auth-token';
const BACKEND_REQUEST_ACCESS_TOKEN = 'x-access-token';

const AUTH_FAILED_MESSAGE = 'Authentication failed';

export const GraphQlRequestContext = createContext<GraphQLClient | null>(null);

export const GraphQlRequestProvider = ({ children }: PropsWithChildren<unknown>) => {
  const auth = useAuth();

  const requestMemo = useMemo(() => {
    const token = auth.getToken();

    if (token && config?.service?.GRAPHQL_URL) {
      return new GraphQLClient(config.service.GRAPHQL_URL, {
        headers: {
          [BACKEND_REQUEST_ACCESS_TOKEN]: token
        }
      });
    }

    return null;
  }, [auth]);

  return <GraphQlRequestContext.Provider value={requestMemo}>{children}</GraphQlRequestContext.Provider>;
};
