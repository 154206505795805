// Using an alias to keep the props name of material.
// This file needs to import the correct MUI_COMPONENT_NAME_PROPS and the route inside @mui
import { CardProps as MuiCardProps } from '@mui/material/Card';

export interface AuthCardProps extends MuiCardProps {
  header?: string;
  subHeader?: string;
  showTittle?: boolean;
}

export const DefaultProps = {
  showTittle: true
};
