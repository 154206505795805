import { AxiosInstance, AxiosResponse } from 'axios';
import config from '../../config';
import {
  CsvExportOptionsEnum,
  ICriteria,
  IExportCSV,
  IImportCsv,
  IImportCsvResult,
  IPageableResponse,
  IProject,
  ProjectDetails
} from '@recone/models';

const GET_PROJECTS_PATH = 'project/list';
const CREATE_PROJECT_PATH = 'project/add';
const CREATE_SAVED_TAB = 'project/saved-tabs';
const UPDATE_PROJECT_PATH = 'project/update';
const DELETE_PROJECT_PATH = 'project/remove';
const GET_PROJECT = 'project/byId';
const IMPORT_CSV_PROJECT = 'project/import';
const EXPORT_CSV_PROJECT = 'project/export';
const GET_PROJECT_EMAILS = 'project/request-emails';
const SET_DEFAULT_CRITERIA = 'project/set-default-criteria';
const UPLOAD_CV = 'project/upload-cv';
const GET_ONE_CV = 'project/get-one-cv';
const DELETE_CV = 'project/remove-cv';

export const getProjects = (
  axiosInstance: AxiosInstance,
  teamId: string,
  page = 0,
  limit = config.service.QUERY_DEFAULT_LIMIT
): Promise<AxiosResponse<IPageableResponse<IProject>>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${GET_PROJECTS_PATH}?page=${page}&limit=${limit}`, {
    headers: { teamId }
  });

export const createProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  projectName: string,
  description: string,
  candidatesNumber: number
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${CREATE_PROJECT_PATH}`,
    { project: { name: projectName, description, candidatesNumber } },
    { headers: { teamId } }
  );

export const updateProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  project: Partial<IProject>
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.put(`${config.service.BASE_URL}/${UPDATE_PROJECT_PATH}`, { project: project }, { headers: { teamId } });

export const deleteProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  projectId: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.delete(`${config.service.BASE_URL}/${DELETE_PROJECT_PATH}/${projectId}`, {
    headers: { teamId }
  });

export const getProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  projectId: string
): Promise<AxiosResponse<ProjectDetails>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${GET_PROJECT}/${projectId}`, { headers: { teamId } });

export const importCsvToProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  payload: IImportCsv[],
  projectId: string
): Promise<AxiosResponse<IImportCsvResult>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${IMPORT_CSV_PROJECT}`,
    { payload: payload, projectId: projectId },
    { headers: { teamId } }
  );

export const exportCsvFromProject = (
  axiosInstance: AxiosInstance,
  teamId: string,
  id: string,
  exportOptions: CsvExportOptionsEnum
): Promise<AxiosResponse<IExportCSV>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${EXPORT_CSV_PROJECT}/${id}`,
    {
      id: id,
      exportOptions
    },
    { headers: { teamId } }
  );

export const getProjectEmails = (
  axiosInstance: AxiosInstance,
  teamId: string,
  id: string
): Promise<AxiosResponse<IImportCsv[]>> =>
  axiosInstance.post(`${config.service.BASE_URL}/${GET_PROJECT_EMAILS}/${id}`, {}, { headers: { teamId } });

export const setDefaultCriteria = (
  axiosInstance: AxiosInstance,
  teamId: string,
  projectId: string,
  criteriaId: string | null
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.put(
    `${config.service.BASE_URL}/${SET_DEFAULT_CRITERIA}`,
    { projectId, criteriaId },
    { headers: { teamId } }
  );

export const createSavedTab = (
  axiosInstance: AxiosInstance,
  teamId: string,
  parentProject: string,
  name: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${CREATE_SAVED_TAB}`,
    { project: { name, parentProject } },
    { headers: { teamId } }
  );

export const deleteSavedTab = (
  axiosInstance: AxiosInstance,
  teamId: string,
  projectId: string,
  savedTabId: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.delete(`${config.service.BASE_URL}/${CREATE_SAVED_TAB}/${projectId}/${savedTabId}`, {
    headers: { teamId }
  });

export const attachCvToCandidate = (
  axiosInstance: AxiosInstance,
  teamId: string,
  formData: FormData
): Promise<AxiosResponse> =>
  axiosInstance.post(`${config.service.BASE_URL}/${UPLOAD_CV}`, formData, {
    headers: {
      teamId
    }
  });

export const getCandidateCv = (
  axiosInstance: AxiosInstance,
  teamId: string,
  profileId: string
): Promise<AxiosResponse> =>
  axiosInstance.get(`${config.service.BASE_URL}/${GET_ONE_CV}`, {
    headers: { teamId },
    params: { profileForTeamId: profileId }
  });
