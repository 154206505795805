export enum EModifier {
  Read = 1,
  Write = 2
}

export enum EAccessLevel {
  SUPER_ADMIN = 'SUPER_ADMIN',
  TEAM_OWNER = 'TEAM_OWNER',
  CUSTOM_ROLE = 'CUSTOM_ROLE'
}

export enum EAccessModifier {
  MODIFY_TEAM = 'MODIFY_TEAM'
}
