import React, { FC } from 'react';
import './PasswordStrengthIndicator.scss';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import vars from './../../styles/Theme/vars.module.scss';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

export type PasswordStrength = {
  contains: string[];
  length: number;
  id: number;
  value: string;
};

interface PasswordStrengthIndicatorProps {
  password: PasswordStrength;
}

const PasswordStrengthIndicator: FC<PasswordStrengthIndicatorProps> = (props) => (
  <Stack className="PasswordStrengthIndicator" data-testid="PasswordStrengthIndicator">
    {props.password.value !== '' && (
      <>
        <Stack direction="row" spacing={1}>
          <Typography
            className="IndicatorBar"
            component="div"
            sx={{ background: props.password.id >= 0 ? vars.danger : '' }}
            variant="subtitle1"></Typography>
          <Typography
            className="IndicatorBar"
            component="div"
            sx={{ background: props.password.id >= 1 ? vars.accentColour : '' }}
            variant="subtitle1"></Typography>
          <Typography
            className="IndicatorBar"
            component="div"
            sx={{ background: props.password.id >= 2 ? vars.warningLight : '' }}
            variant="subtitle1"></Typography>
          <Typography
            className="IndicatorBar"
            component="div"
            sx={{ background: props.password.id >= 3 ? vars.success : '' }}
            variant="subtitle1"></Typography>
        </Stack>
        <Typography fontWeight={500} marginY={1} textAlign="center" variant="subtitle2">
          Your password is {props.password.value}!
        </Typography>
      </>
    )}
    <Alert
      icon={false}
      sx={{
        backgroundColor: '#F3F4FE',
        color: 'primary.main',
        '& .MuiAlert-icon': {
          color: 'primary.main'
        }
      }}>
      <Grid container justifyContent="space-evenly" textAlign="start">
        <Typography fontWeight={500} variant="subtitle2">
          Password must include at least 8 characters:
        </Typography>
        <Stack>
          <Typography
            color={props.password.contains.includes('lowercase') ? vars.success : ''}
            component="li"
            variant="subtitle2">
            1 Lowercase
          </Typography>
          <Typography
            color={props.password.contains.includes('uppercase') ? vars.success : ''}
            component="li"
            variant="subtitle2">
            1 Uppercase
          </Typography>
        </Stack>
        <Stack>
          <Typography
            color={props.password.contains.includes('number') ? vars.success : ''}
            component="li"
            variant="subtitle2">
            1 Number
          </Typography>
          <Typography
            color={props.password.contains.includes('symbol') ? vars.success : ''}
            component="li"
            variant="subtitle2">
            1 Special character
          </Typography>
        </Stack>
      </Grid>
    </Alert>
  </Stack>
);

export default PasswordStrengthIndicator;
