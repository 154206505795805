import { styled } from '@mui/material/styles';
import { ButtonProps } from './Button.types';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => {
  return {
    color: '#fff',
    minWidth: 130,
    textTransform: 'initial',
    fontWeight: 400,
    '&.MuiButton-outlined': {
      color: theme.palette.primary.main
    },
    '&.MuiButton-text': {
      color: theme.palette.primary.main
    },
    '&.MuiButton-sizeMedium': {
      height: 32
    },
    '&.AuthFormBtn': {
      margin: '30px 0 30px 0'
    },
    '&.MuiBase': {
      minWidth: 64
    },
    '&.FilterButton': {
      height: 22,
      minWidth: 50,
      fontSize: 12,
    },
    '&.AllConjunctionButton': {
      borderTopRightRadius: 0, borderBottomRightRadius: 0
    },
    '&.AnyConjunctionButton': {
      borderTopLeftRadius: 0, borderBottomLeftRadius: 0
    }
  };
});

export default StyledButton;
